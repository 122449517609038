import { type CSSProperties } from "react";
import { cn } from "src/lib/utils";
import Close from "src/svg/Close";
import PlayIcon from "src/svg/PlayIcon";
import {
  Dialog,
  DialogClose,
  DialogContainer,
  DialogContent,
  DialogTrigger,
  DialogVideo,
} from "./dialog";

interface IVideoPlayer {
  videoCode: string;
  thumbnail: string;
  style?: CSSProperties;
  notDark?: boolean;
  horizontal?: boolean;
  isHorizontal?: boolean;
  imageProps?: {
    className?: string;
  };
}

const VideoPlayer = ({
  videoCode,
  thumbnail,
  notDark,
  style,
  horizontal,
  imageProps,
}: IVideoPlayer) => {
  return (
    <>
      <Dialog>
        <DialogTrigger>
          <div className="relative cursor-pointer">
            <div className="relative">
              <img
                src={thumbnail}
                alt="thumbnail"
                className={cn(
                  "w-full h-full",
                  imageProps?.className,
                  notDark ? "" : "grayscale"
                )}
              />
            </div>
            <div className="top-1/2 left-1/2 z-[2] absolute -translate-x-1/2 -translate-y-1/2">
              <PlayIcon />
            </div>
            <div
              className="block top-0 left-0 z-[1] absolute w-full h-full"
              style={style}
            />
          </div>
        </DialogTrigger>
        <DialogContainer>
          <DialogContent variants={{}} className="relative w-full h-full">
            <div className="top-1/2 left-1/2 absolute w-full max-w-[50dvh] sm:max-w-[100dvh] -translate-x-1/2 -translate-y-1/2 aspect-video">
              <DialogVideo
                code={videoCode}
                className="rounded-[4px] h-full object-cover"
                horizontal={horizontal}
              />
            </div>
          </DialogContent>
          <DialogClose className="md:top-6 right-0 md:right-6 fixed flex justify-center items-center p-1 rounded-full w-10 h-10">
            <Close />
          </DialogClose>
        </DialogContainer>
      </Dialog>
    </>
  );
};

export default VideoPlayer;
