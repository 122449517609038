

const PlayIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={70}
    viewBox="0 0 70 70"
    fill="none"
  >
    <g clipPath="url(#clip0_640_135)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5833 0C44.133 0 52.7792 3.87115 59.0378 10.1301C65.2957 16.388 69.1667 25.0341 69.1667 34.5837C69.1667 44.1333 65.2955 52.7793 59.0378 59.0379C52.779 65.2957 44.1324 69.1667 34.5833 69.1667C25.0343 69.1667 16.3875 65.2955 10.1289 59.0379C3.87099 52.7792 0 44.1326 0 34.5837C0 25.0347 3.87119 16.388 10.1302 10.1295C16.3882 3.87162 25.0337 0 34.5833 0Z"
        fill="#F9F9F9"
        fillOpacity="0.21"
      />
      <path
        d="M10.4837 10.4831L10.4838 10.4831C16.6518 4.31509 25.1717 0.5 34.5833 0.5C43.9951 0.5 52.5156 4.31463 58.6842 10.4836L59.0378 10.1301L58.6842 10.4837C64.8522 16.6516 68.6667 25.172 68.6667 34.5837C68.6667 43.9954 64.852 52.5157 58.6842 58.6844C52.5153 64.8522 43.9944 68.6667 34.5833 68.6667C25.1723 68.6667 16.6512 64.852 10.4825 58.6844C4.31448 52.5156 0.5 43.9947 0.5 34.5837C0.5 25.1726 4.31467 16.6516 10.4837 10.4831Z"
        stroke="url(#paint0_linear_640_135)"
        strokeOpacity="0.5"
      />
      <path
        d="M58.7434 10.4248C52.5598 4.24068 44.0184 0.416626 34.5836 0.416626C25.1489 0.416626 16.608 4.24113 10.4251 10.4241C4.24109 16.6077 0.416992 25.1497 0.416992 34.5837C0.416992 44.0177 4.2409 52.5595 10.4239 58.7432C16.6075 64.926 25.1496 68.75 34.5836 68.75C44.0177 68.75 52.5597 64.9261 58.7434 58.7432C64.9262 52.5596 68.7503 44.0183 68.7503 34.5837C68.7503 25.149 64.9264 16.6077 58.7434 10.4248ZM58.7434 10.4248L59.0309 10.1373"
        stroke="url(#paint1_linear_640_135)"
        strokeOpacity="0.5"
      />
      <path
        d="M47.2458 34.5849C47.2458 33.2963 46.6007 32.1283 45.4715 31.484L31.3584 23.0268C29.6244 21.9797 27.4067 22.5436 26.3583 24.2752C26.0356 24.839 25.834 25.4833 25.834 26.1277V43.0421C25.834 45.0558 27.4469 46.6667 29.4631 46.6667C30.1082 46.6667 30.7938 46.5055 31.3582 46.1431L45.4714 37.6858C46.6006 37.0415 47.2861 35.8736 47.2458 34.5849Z"
        fill="white"
        fillOpacity="0.8"
      />
      <path
        d="M47.2458 34.5849C47.2458 33.2963 46.6007 32.1283 45.4715 31.484L31.3584 23.0268C29.6244 21.9797 27.4067 22.5436 26.3583 24.2752C26.0356 24.839 25.834 25.4833 25.834 26.1277V43.0421C25.834 45.0558 27.4469 46.6667 29.4631 46.6667C30.1082 46.6667 30.7938 46.5055 31.3582 46.1431L45.4714 37.6858C46.6006 37.0415 47.2861 35.8736 47.2458 34.5849Z"
        fill="url(#paint2_linear_640_135)"
        fillOpacity="0.16"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_640_135"
        x1="69.1907"
        y1="69.1668"
        x2="-9.16425"
        y2="57.0349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0} />
        <stop offset="0.494792" stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_640_135"
        x1="68.7741"
        y1="68.75"
        x2="-8.63684"
        y2="56.7643"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0} />
        <stop offset="0.494792" stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_640_135"
        x1="47.2549"
        y1="46.6667"
        x2="22.8742"
        y2="43.3218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0} />
        <stop offset="0.494792" stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <clipPath id="clip0_640_135">
        <rect width={70} height={70} fill="white" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default PlayIcon