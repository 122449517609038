

const Close = () => {
  return (
    <div className="relative w-8 h-8">
      <div className=" bg-[#262b30] absolute top-0 left-0 w-full h-full rounded-rounded" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        className="w-full h-full"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.5833 0C44.133 0 52.7792 3.87115 59.0378 10.1301C65.2957 16.388 69.1667 25.0341 69.1667 34.5837C69.1667 44.1333 65.2955 52.7793 59.0378 59.0379C52.779 65.2957 44.1324 69.1667 34.5833 69.1667C25.0343 69.1667 16.3875 65.2955 10.1289 59.0379C3.87099 52.7792 0 44.1326 0 34.5837C0 25.0347 3.87119 16.388 10.1302 10.1295C16.3882 3.87162 25.0337 0 34.5833 0Z"
          fill="white"
          fill-opacity="0.04"
        />
        <path
          d="M10.4837 10.4831L10.4838 10.4831C16.6518 4.31509 25.1717 0.5 34.5833 0.5C43.9951 0.5 52.5156 4.31463 58.6842 10.4836L59.0378 10.1301L58.6842 10.4837C64.8522 16.6516 68.6667 25.172 68.6667 34.5837C68.6667 43.9954 64.852 52.5157 58.6842 58.6844C52.5153 64.8522 43.9944 68.6667 34.5833 68.6667C25.1723 68.6667 16.6512 64.852 10.4825 58.6844C4.31448 52.5156 0.5 43.9947 0.5 34.5837C0.5 25.1726 4.31467 16.6516 10.4837 10.4831Z"
          stroke="white"
          stroke-opacity="0.16"
        />
        <path
          d="M10.4249 10.4241C16.6078 4.24113 25.1487 0.416626 34.5834 0.416626C44.0182 0.416626 52.5596 4.24068 58.7432 10.4248C64.9262 16.6077 68.7501 25.149 68.7501 34.5837C68.7501 44.0183 64.926 52.5596 58.7432 58.7432C52.5595 64.9261 44.0175 68.75 34.5834 68.75C25.1494 68.75 16.6073 64.926 10.4237 58.7432C4.24065 52.5595 0.416748 44.0177 0.416748 34.5837C0.416748 25.1497 4.24084 16.6077 10.4249 10.4241Z"
          fill="white"
          fill-opacity="0.04"
        />
        <path
          d="M58.7432 10.4248C52.5596 4.24068 44.0182 0.416626 34.5834 0.416626C25.1487 0.416626 16.6078 4.24113 10.4249 10.4241C4.24084 16.6077 0.416748 25.1497 0.416748 34.5837C0.416748 44.0177 4.24065 52.5595 10.4237 58.7432C16.6073 64.926 25.1494 68.75 34.5834 68.75C44.0175 68.75 52.5595 64.9261 58.7432 58.7432C64.926 52.5596 68.7501 44.0183 68.7501 34.5837C68.7501 25.149 64.9262 16.6077 58.7432 10.4248ZM58.7432 10.4248L59.0307 10.1373"
          stroke="white"
          stroke-opacity="0.16"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2"
      >
        <path
          d="M7.46666 4.26666C7.19372 4.26666 6.92049 4.37062 6.71249 4.57916L4.57916 6.71249C4.16209 7.12956 4.16209 7.80482 4.57916 8.22082L12.3583 16L4.57916 23.7792C4.16209 24.1962 4.16209 24.8715 4.57916 25.2875L6.71249 27.4208C7.12956 27.8379 7.80482 27.8379 8.22082 27.4208L16 19.6417L23.7792 27.4208C24.1952 27.8379 24.8715 27.8379 25.2875 27.4208L27.4208 25.2875C27.8379 24.8704 27.8379 24.1952 27.4208 23.7792L19.6417 16L27.4208 8.22082C27.8379 7.80482 27.8379 7.12849 27.4208 6.71249L25.2875 4.57916C24.8704 4.16209 24.1952 4.16209 23.7792 4.57916L16 12.3583L8.22082 4.57916C8.01229 4.37062 7.73959 4.26666 7.46666 4.26666Z"
          fill="#C2CAD6"
          fill-opacity="0.8"
        />
      </svg>
    </div>
  );
};

export default Close;
